<script setup lang="ts">
import Logo from '@mui/assets/logos/medmastery-color.svg?component';
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { SubscriptionType } from "~/composables/useAuthentication";

const { user } = useUserStore();
const { headerSearchClicked } = useGlobalStore();
const router = useRouter();

const searchIconClicked = () => {
  if (router.currentRoute.value.path !== '/courses') {
    navigateTo('/courses?focus=search-field');
  }

  headerSearchClicked();
};

</script>

<template>
  <div class="app-header sticky top-0 z-[900] bg-white">
    <div class="content py-2 flex items-center flex-nowrap justify-between">
      <NuxtLink to="/dashboard">
        <div class="flex items-center">
          <Logo class="order-1 -mb-1" />
        </div>
      </NuxtLink>

      <div class="w-full xl:hidden flex" />

      <AppNavigation />

      <div class="w-full xl:flex hidden" />

      <NuxtLink
        v-if="user?.subscription.type === SubscriptionType.trial"
        to="/pricing"
      >
        <Button
          label="Get full access"
          rounded
          :pt="{
            label: {
              class: '!text-nowrap'
            }
          }"
          class="bg-yellow-50 text-neutral-80 font-bold text-bold border-none text-nowrap lg:mr-4 mr-2 xl:ml-0 ml-4 order-2 xl:flex hidden"
        />
      </NuxtLink>

      <span class="xl:order-3 order-2 xl:mr-4 mr-6">
        <Button
          rounded
          class="xl:p-3 p-1 xl:!bg-neutral-20 bg-transparent hover:!bg-neutral-10 border-none"
          @click="searchIconClicked"
        >
          <FontAwesomeIcon
            icon="fa-magnifying-glass"
            class="xl:!w-4 !w-6 xl:!h-4 !h-6 xl:text-neutral-100 text-neutral-90"
          />
        </Button>
      </span>

      <AppHeaderUserProfile class="order-4" />
    </div>

    <Divider class="m-0" />
  </div>
</template>

<style lang="scss">
@import '@mui/assets/styles/utility.scss';

.app-header {
  > .content {
    @include container-sizer('page');
  }
}
</style>
